@import '_var';
@import '_fonts';
@import '_typography';

// To be used as global styles
// -------------------------------

html,
body {
  width: 100%;
  height: 100%;
}

body {
  @include body-m;
  cursor: default;
  overflow-y: scroll;
  font-family: OpenSauceOne, sans-serif;
  color: $black;
  -webkit-font-smoothing: antialiased;
}

app-root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

body.modal--open {
  overflow: hidden !important;
}

a {
  color: $digitalBlue;
  text-decoration: none;
  cursor: pointer;
}

@keyframes pulsating {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.6;
  }
}

.registrationBox {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 430px;
  box-sizing: border-box;
  background: white;
  padding: 16px 16px;

  @media #{$bp-medium} {
    flex: 1;
    padding: 32px 24px;
    border-radius: 6px;
  }
}

.signup-page {
  margin: 24px 0;

  @media #{$bp-medium} {
    margin-top: 36px;
  }
}

.page-title {
  @include title-m;
  text-align: center;
  margin-bottom: 24px;
}

:root {
  --input-border-color: #{$grey200};
  --input-border-width: 1px;
  --input-border-style: solid;
  --input-border-radius: 2px;
  --input-invalid-color: #{$red1000};
}

.uppercase {
  text-transform: uppercase;
}

// accordion items styles
.accordion__content {
  h4 {
    @include subtitle-m;
    margin-block-end: 8px;
  }

  p {
    margin: 0;
  }

  p:not(:last-of-type) {
    margin-block-end: 24px;
  }
}

.accordion__content:last-child {
  padding-bottom: 0 !important;
}

// We don't want to display the legacy google sign in button. We need to keep it in the DOM for google auth to work.
.legacy-g-signin__button {
  position: absolute;
  // For some reasons 0 opacity will make the element
  // stop receiving click events sometimes. This is
  // functionally invisible but still clickable.
  opacity: 0.0001;
  max-width: 100%;
  overflow: hidden;
}

// We don't want to display the recaptcha badge
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge {
  visibility: hidden;
}
.recaptchaDisclaimer {
  @include body-xs;
  color: $white;

  a {
    color: $white;
    text-decoration: underline;
  }
}
.recaptchaDisclaimer__light {
  @include body-xs;
  color: $black;

  a {
    color: $digitalBlue;
    text-decoration: none;
  }
}

@mixin inspectorEffect($content) {
  outline: 1px solid red !important;
  pointer-events: all !important; // for hover to work
  &:hover::after {
    z-index: 9999;

    @include body-xs;
    text-align: center;
    text-transform: none;
    font-family: OpenSauceOne, sans-serif;
    content: $content;
    white-space: pre;
    position: fixed;
    background: red;
    color: white;
    padding: 2px;
    bottom: 0;
    right: 0;
    min-width: max-content;
    min-height: max-content;
  }
}
body.inspector {
  // Currently the inspector is only used for translation keys and img src
  // That means that we don't support inspecting background images for example
  *[translate-key] {
    @include inspectorEffect($content: 'Translation key(s): ' attr(translate-key));
  }

  picture[qa-url] {
    @include inspectorEffect($content: 'Image url: ' attr(qa-url));
  }

  #__red-dot__ {
    &::after {
      /* stylelint-disable scss/operator-no-newline-after */
      content: 'In inspector mode. Click the magnifying glass in red dot again to exit.\A' +
        "You can highlight text that isn't outlined by the red dot to inspect it. Hover over outlined elements to inspect them.\A" +
        'If there are multiple results for a translation key, you might need developer input for which one is correct.' +
        "\ASometimes it's obvious and you can use trial and error to find the correct one.";
      /* stylelint-enable scss/operator-no-newline-after */
      white-space: break-spaces;

      @include body-xs;
      position: fixed;
      background: red;
      color: white;
      padding: 16px;
      bottom: 0;
      max-width: 50%;
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

// Google auth
#credential_picker_iframe {
  z-index: 10000 !important;
}
