<div class="modal__container">
  <div class="modal__background" (click)="modal.skipBackdropClose || clear()"></div>

  <div class="modal" [class.sheet]="modal.sheetStyle" [@fadeAnimation]="fadeAnimation">
    @if (!modal.hideCloseButton) {
      <div (click)="clear()" class="modal__close"></div>
    }

    <div class="modal__body" [class.sheet]="modal.sheetStyle">
      @if (modal.titleKey) {
        <div class="modal__title">{{ modal.titleKey | translate }}</div>
      }

      <ng-template modalHost />

      @if (modal.controls) {
        <div class="modal__controls">
          @for (control of modal.controls; track control) {
            <app-button text="blue" [label]="control.key | translate" (click)="clear(control)" />
          }
        </div>
      }

      @if (!modal.skipControls && !modal.controls) {
        <div class="modal__controls">
          <app-button text="blue" [label]="'btn-ok' | translate" (click)="clear()" />
        </div>
      }
    </div>
  </div>
</div>
