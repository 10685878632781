import { Component, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NavigationEnd, Router } from '@angular/router'
import { Goal } from '@naturalcycles/shared'
import { quizParentSlug } from '@src/app/constants/quiz'
import { Decorate } from '@src/app/core/decorators/decorators'
import { NavService } from '@src/app/core/services/nav.service'
import { QaService } from '@src/app/core/services/qa.service'
import { SessionService } from '@src/app/core/services/session.service'
import { getState } from '@src/app/core/store/signalStore'
import { ErrorHandlerType } from '@src/app/shared/typings/enum/error-handler'
import { AnyPage, ClearbluePage, ManageAccountPage, Page } from '@src/app/shared/typings/enum/pages'
import { LoaderType } from '@src/app/shared/typings/ui'
import { env } from '@src/environments/environment'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  protected nonSignupFlow?: boolean
  protected isHidden?: boolean

  router = inject(Router)
  navService = inject(NavService)
  sessionService = inject(SessionService)
  qaService = inject(QaService)

  private defaultLogo = 'assets/img/nc-logo.svg'
  private defaultLogoPurple = 'assets/img/nc-logo-brand-purple.svg'

  protected Page = Page
  protected ManageAccountPage = ManageAccountPage
  protected websiteUrl = env.websiteHost
  protected currentPath = ''
  protected currentPage?: AnyPage
  protected logo = this.defaultLogo

  protected quizParentSlug = quizParentSlug
  protected greyBackground = false

  constructor() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { path } = this.navService.parseUrl(event.url)
        this.currentPath = path
        const parts = path.split('/')
        this.currentPage = parts[parts.length - 1] as Page | ManageAccountPage
        this.isHidden = this.isHiddenPage(this.currentPage)
        if (this.isHidden) {
          return
        }

        this.nonSignupFlow = this.nonSignupFlowPage(path)
        this.greyBackground =
          (this.currentPage === Page.plans && !this.nonSignupFlow) ||
          this.currentPage === (ClearbluePage.offer as AnyPage)

        const { account } = getState()

        const defaultLogoPages: AnyPage[] = [ManageAccountPage.account, ClearbluePage.success]

        if (defaultLogoPages.includes(this.currentPage)) {
          this.logo = this.defaultLogo
        } else if (
          !this.nonSignupFlow &&
          (this.currentPage === Page.measuringDevice || this.currentPage === Page.plans)
        ) {
          this.logo = account.goal
            ? `assets/img/nc-${Goal[account.goal]}-logo-purple.svg`
            : this.defaultLogoPurple
        } else if (this.currentPage === Page.goal || !this.nonSignupFlow) {
          this.logo = this.defaultLogoPurple
        }
      }
    })
  }

  @Decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async handleLogout(): Promise<void> {
    await this.sessionService.logout()
  }

  private isManageAccountPagePath(path: string): boolean {
    return path.includes(ManageAccountPage.account)
  }

  private nonSignupFlowPage(path: string): boolean {
    const parts = path.split('/')
    const currentPage = parts[parts.length - 1]!
    const nonSignupFlowPage = [
      Page.code,
      Page.consent,
      Page.resetPassword,
      ClearbluePage.success,
    ] as string[]

    return nonSignupFlowPage.includes(currentPage) || this.isManageAccountPagePath(path)
  }

  private isHiddenPage(path: string): boolean {
    const hiddenPages: string[] = [
      Page.login,
      Page.signup,
      Page.coveredByInsurance,
      Page.providerInfo,
      Page.chooseProvider,
      Page.clarifyProvider,
    ]
    return hiddenPages.includes(path) || this.currentPath.includes(quizParentSlug)
  }
}
