import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { Route, RouterModule, Routes } from '@angular/router'
import { LANG_DEFAULT } from '@naturalcycles/shared'
import { ab312Routes } from '@src/ab-312/ab312'
import { ab348Routes } from '@src/ab-348/ab348'
import { ab351Routes } from '@src/ab-351/ab351'
import { ab355Routes } from '@src/ab-355/ab355'
import { ab356Routes } from '@src/ab-356/ab-356'
import { ab358Routes } from '@src/ab-358/ab-358'
import { BootstrapDone } from '@src/app/guards/bootstrap-done'
import { CanActivatePagesModule } from '@src/app/guards/can.activate.pages.module'
import { Error404Page } from '@src/app/pages/404/404.page.component'
import { StaticMainPage } from '@src/app/pages/static-main/static-main.page'
import { SharedModule } from '@src/app/shared/shared.module'
import {
  ActivatePage,
  AnyPage,
  ClearbluePage,
  ManageAccountPage,
  Page,
  ParentPage,
} from '@src/app/shared/typings/enum/pages'
import {
  ClearblueFlowService,
  getFlowGuard,
  PageFlowService,
  SignupFlowService,
} from '../core/services/flow.service'
import { PagePath } from '../core/util/pagePath'
import { CanActivateAccountPage } from '../guards/can.activate.account.page'
import { CanActivateAccountPaymentPage } from '../guards/can.activate.accountPayment.page'
import { CanActivateActivateCredentials } from '../guards/can.activate.activateCredentials'
import { CanActivateActivateOrderSummary } from '../guards/can.activate.activateOrderSummary'
import { CanActivateOuraPage } from '../guards/can.activate.oura.page'
import { canActivateQuiz } from '../guards/can.activate.quiz'
import { PageOutletComponent } from './outlet.component'

const CLEARBLUE_ROUTES: Routes = [
  {
    path: ParentPage.clearblue,
    providers: [
      {
        provide: PageFlowService,
        useClass: ClearblueFlowService,
      },
    ],
    loadChildren: () =>
      (
        [
          {
            path: '',
            redirectTo: ClearbluePage.code,
          },
          {
            path: ClearbluePage.code,
            loadComponent: () =>
              import('./clearblue/code/code.page.component').then(m => m.CodePageComponent),
          },
          {
            path: ClearbluePage.success,
            loadComponent: () =>
              import('./clearblue/success/success.page.component').then(
                m => m.SuccessPageComponent,
              ),
          },
          {
            path: ClearbluePage.failure,
            loadComponent: () =>
              import('./clearblue/failure/failure.page.component').then(
                m => m.FailurePageComponent,
              ),
          },
          {
            path: Page.orderSummary,
            loadComponent: () =>
              import('./clearblue/order-summary/order-summary.page.component').then(
                m => m.OrderSummaryPage,
              ),
          },
          {
            path: ClearbluePage.offer,
            loadComponent: () =>
              import('./clearblue/offer/offer.page.component').then(m => m.OfferPageComponent),
          },
          {
            path: Page.credentials,
            loadChildren: () =>
              import('./credentials/credentials.page.module').then(m => m.CredentialsPageModule),
          },
          {
            path: Page.address,
            loadComponent: () =>
              import('./address/address.page.component').then(m => m.AddressPage),
          },
          {
            path: Page.payment,
            loadChildren: () =>
              import('./payment/payment.page.module').then(m => m.PaymentPageModule),
          },
        ] satisfies Route[]
      ).map((route: Route) => ({
        ...route,
        canActivate: route.data?.['skipFlowGuard']
          ? []
          : [getFlowGuard(new PagePath(ParentPage.clearblue, route.path as AnyPage))],
      })),
  },
]

const QUIZ_ROUTE: Route = {
  path: ParentPage.quiz,
  canLoad: [BootstrapDone],
  canActivateChild: [canActivateQuiz],
  loadChildren: () =>
    (
      [
        // section 1 (figma pre-questions)
        {
          path: Page.fertilityGoal,
          loadComponent: () =>
            import('./quiz/questions/fertility-goal/fertility-goal.page.component').then(
              m => m.FertilityGoalPage,
            ),
        },
        // We have links to the now-removed intro page in various places, so we need to redirect
        {
          path: 'intro',
          redirectTo: Page.fertilityGoal,
        },
        {
          path: Page.privacy,
          loadComponent: () =>
            import('./quiz/privacy/privacy.page.component').then(m => m.PrivacyPage),
        },
        {
          path: Page.name,
          loadComponent: () =>
            import('./quiz/questions/name/name.page.component').then(m => m.NamePage),
        },
        {
          path: Page.usps,
          loadComponent: () => import('./quiz/usps/usps.page.component').then(m => m.UspsPage),
        },
        {
          path: Page.birthday,
          loadComponent: () =>
            import('./quiz/questions/birthday/birthday.page.component').then(m => m.BirthdayPage),
        },
        // section 2 (figma goal+bc method)
        {
          path: Page.fertilityGoalInfo,
          loadComponent: () =>
            import('./quiz/fertility-goal-info/fertility-goal-info.page.component').then(
              m => m.FertilityGoalInfoPage,
            ),
        },
        // section 2 -> prevent
        {
          path: Page.birthControlMethod,
          loadComponent: () =>
            import(
              './quiz/questions/birth-control-method/birth-control-method.page.component'
            ).then(m => m.BirthControlMethodPage),
        },
        ...ab348Routes,
        ...ab351Routes,
        ...ab356Routes,
        ...ab355Routes,
        ...ab358Routes,
        {
          path: Page.birthControlMethodInfo,
          loadComponent: () =>
            import('./quiz/bc-method-info/birth-control-method-info.page.component').then(
              m => m.BirthControlMethodInformationPage,
            ),
        },
        {
          path: Page.sideEffects,
          loadComponent: () =>
            import('./quiz/questions/side-effects/side-effects.page.component').then(
              m => m.SideEffectsPage,
            ),
        },
        {
          path: Page.sideEffectsInfo,
          loadComponent: () =>
            import('./quiz/side-effects-info/side-effects-info.page.component').then(
              m => m.SideEffectsInfoPage,
            ),
        },
        // section 2 -> plan
        {
          path: Page.plannedPregnancyTiming,
          loadComponent: () =>
            import(
              './quiz/questions/planned-pregnancy-timing/planned-pregnancy-timing.page.component'
            ).then(m => m.PlannedPregnancyTimingPage),
        },
        {
          path: Page.learnAboutSex,
          loadComponent: () =>
            import('./quiz/learn-about-sex-info/learn-about-sex-info.page.component').then(
              m => m.LearnAboutSexInfoPage,
            ),
        },
        {
          path: Page.menstrualCycleInfo,
          loadComponent: () =>
            import('./quiz/menstrual-cycle-info/menstrual-cycle-info.page.component').then(
              m => m.MenstrualCycleInfoPage,
            ),
        },
        {
          path: Page.sexDriveInfo,
          loadComponent: () =>
            import('./quiz/sex-drive-info/sex-drive-info.page.component').then(
              m => m.SexDriveInfoPage,
            ),
        },
        {
          path: Page.testimonialInfo,
          loadComponent: () =>
            import('./quiz/testimonial-info/testimonial-info.page.component').then(
              m => m.TestimonialInfoPage,
            ),
        },
        {
          path: Page.bestTimeInCycle,
          loadComponent: () =>
            import('./quiz/questions/best-time-in-cycle/best-time-in-cycle.page.component').then(
              m => m.BestTimeInCyclePage,
            ),
        },
        {
          path: Page.knowingYourSexDrive,
          loadComponent: () =>
            import(
              './quiz/questions/knowing-your-sex-drive/knowing-your-sex-drive.page.component'
            ).then(m => m.KnowingYourSexDrivePage),
        },
        {
          path: Page.sexFrequency,
          loadComponent: () =>
            import('./quiz/questions/sex-frequency/sex-frequency.page.component').then(
              m => m.SexFrequencyPage,
            ),
        },
        {
          path: Page.knowPregnantDays,
          loadComponent: () =>
            import('./quiz/questions/know-pregnant-days/know-pregnant-days.page.component').then(
              m => m.KnowPregnantDaysPage,
            ),
        },

        // section 3 (cycle)
        {
          path: Page.hormonalBirthControlUsage,
          loadComponent: () =>
            import(
              './quiz/questions/hormonal-birth-control-usage/hormonal-birth-control-usage.page.component'
            ).then(m => m.HormonalBirthControlUsagePage),
        },
        {
          path: Page.hormonalBirthControlHowLongPlan,
          loadComponent: () =>
            import(
              './quiz/questions/how-long-hormonal-bc/how-long-hormonal-bc.page.component'
            ).then(m => m.HowLongHormonalBcPage),
        },
        {
          path: Page.hormonalBirthControlHowLongPrevent,
          loadComponent: () =>
            import(
              './quiz/questions/how-long-hormonal-bc/how-long-hormonal-bc.page.component'
            ).then(m => m.HowLongHormonalBcPage),
        },
        {
          path: Page.plannedPregnancyInfo,
          loadComponent: () =>
            import('./quiz/planned-pregnancy-info/planned-pregnancy-info.page.component').then(
              m => m.PlannedPregnancyInfoPage,
            ),
        },
        {
          path: Page.cycleLength,
          loadComponent: () =>
            import('./quiz/questions/cycle-length/cycle-length.page.component').then(
              m => m.CycleLengthPage,
            ),
        },
        {
          path: Page.periodRegularity,
          loadComponent: () =>
            import('./quiz/questions/period-regularity/period-regularity.page.component').then(
              m => m.PeriodRegularityPage,
            ),
        },
        {
          path: Page.periodRegularityInfo,
          loadComponent: () =>
            import('./quiz/period-regularity-info/period-regularity-info.page.component').then(
              m => m.PeriodRegularityInfoPage,
            ),
        },
        {
          path: Page.cycleStartDate,
          loadComponent: () =>
            import('./quiz/questions/cycle-start-date/cycle-start-date.page.component').then(
              m => m.CycleStartDatePage,
            ),
        },
        {
          path: Page.medicalConditionsPlan,
          loadComponent: () =>
            import('./quiz/questions/medical-conditions/medical-conditions.page.component').then(
              m => m.MedicalConditionsPage,
            ),
        },
        {
          path: Page.medicalConditionsPrevent,
          loadComponent: () =>
            import('./quiz/questions/medical-conditions/medical-conditions.page.component').then(
              m => m.MedicalConditionsPage,
            ),
        },
        {
          path: Page.medicalConditionsInfo,
          loadComponent: () =>
            import('./quiz/medical-conditions-info/medical-conditions-info.page.component').then(
              m => m.MedicalConditionsInfoPage,
            ),
        },
        {
          path: Page.doctorInvolvement,
          loadComponent: () =>
            import('./quiz/questions/doctor-involvement/doctor-involvement.page.component').then(
              m => m.DoctorInvolvementPage,
            ),
        },
        {
          path: Page.partnerInvolvement,
          loadComponent: () =>
            import('./quiz/questions/partner-involvement/partner-involvement.page.component').then(
              m => m.PartnerInvolvementPage,
            ),
        },
        {
          path: Page.partnerInvolvementInfo,
          loadComponent: () =>
            import('./quiz/partner-involvement-info/partner-involvement-info.page.component').then(
              m => m.PartnerInvolvementInfoPage,
            ),
        },
        {
          path: Page.followPregnancyInfo,
          loadComponent: () =>
            import('./quiz/follow-pregnancy-info/follow-pregnancy-info.page.component').then(
              m => m.FollowPregnancyInfoPage,
            ),
        },
        // section 4 (cycleChanges)
        {
          path: Page.emotionalChanges,
          loadComponent: () =>
            import('./quiz/emotional-changes-info/emotional-changes-info.page.component').then(
              m => m.EmotionalChangesInfoPage,
            ),
        },
        {
          path: Page.cycleChanges,
          loadComponent: () =>
            import('./quiz/questions/cycle-changes/main/cycle-changes.page.component').then(
              m => m.CycleChangesPage,
            ),
        },
        {
          path: Page.cycleChangesEmotions,
          loadComponent: () =>
            import('./quiz/questions/cycle-changes/sub/cycle-changes-emotions.page').then(
              m => m.CycleChangesEmotionsPage,
            ),
        },
        {
          path: Page.cycleChangesBleeding,
          loadComponent: () =>
            import('./quiz/questions/cycle-changes/sub/cycle-changes-bleeding.page').then(
              m => m.CycleChangesBleedingPage,
            ),
        },
        {
          path: Page.cycleChangesSkin,
          loadComponent: () =>
            import('./quiz/questions/cycle-changes/sub/cycle-changes-skin.page').then(
              m => m.CycleChangesSkinPage,
            ),
        },
        {
          path: Page.cycleChangesSexAndLibido,
          loadComponent: () =>
            import('./quiz/questions/cycle-changes/sub/cycle-changes-sex-and-libido.page').then(
              m => m.CycleChangesSexAndLibidoPage,
            ),
        },
        {
          path: Page.cycleChangesCervicalMucus,
          loadComponent: () =>
            import('./quiz/questions/cycle-changes/sub/cycle-changes-cervical-mucus.page').then(
              m => m.CycleChangesCervicalMucusPage,
            ),
        },
        {
          path: Page.cycleChangesPain,
          loadComponent: () =>
            import('./quiz/questions/cycle-changes/sub/cycle-changes-pain.page').then(
              m => m.CycleChangesPainPage,
            ),
        },
        {
          path: Page.trackingInfo,
          loadComponent: () =>
            import('./quiz/tracking-info/tracking-info.page.component').then(
              m => m.TrackingInfoPage,
            ),
        },

        // section 5 (routine )
        {
          path: Page.naturalMethod,
          loadComponent: () =>
            import('./quiz/natural-method/natural-method.page.component').then(
              m => m.NaturalMethodPage,
            ),
        },
        {
          path: Page.yourMeasuringDevice,
          loadComponent: () =>
            import('./quiz/measuring-device/measuring-device.page.component').then(
              m => m.MeasuringDeviceInfoPage,
            ),
        },
        {
          path: Page.equallyEffectiveInfo,
          loadComponent: () =>
            import('./quiz/equally-effective-info/equally-effective-info.page.component').then(
              m => m.EquallyEffectiveInfoPage,
            ),
          data: { page: Page.equallyEffectiveInfo },
        },
        {
          path: Page.nightSleep,
          loadComponent: () =>
            import('./quiz/questions/night-sleep/night-sleep.page.component').then(
              m => m.NightSleepPage,
            ),
          data: { page: Page.nightSleep },
        },
        {
          path: Page.troubleGettingBackToSleep,
          loadComponent: () =>
            import(
              './quiz/questions/trouble-getting-back-to-sleep/trouble-getting-back-to-sleep.page.component'
            ).then(m => m.TroubleGettingBackToSleepPage),
          data: { page: Page.troubleGettingBackToSleep },
        },
        {
          path: Page.sleepConsistency,
          loadComponent: () =>
            import('./quiz/questions/sleep-consistency/sleep-consistency.page.component').then(
              m => m.SleepConsistencyPage,
            ),
          data: { page: Page.sleepConsistency },
        },
        {
          path: Page.spendingDaysInfo,
          loadComponent: () =>
            import('./quiz/spending-days-info/spending-days-info.page.component').then(
              m => m.SpendingDaysInfoPage,
            ),
          data: { page: Page.spendingDaysInfo },
        },
        {
          path: Page.feelingStressed,
          loadComponent: () =>
            import('./quiz/questions/feeling-stressed/feeling-stressed.page.component').then(
              m => m.FeelingStressedPage,
            ),
          data: { page: Page.feelingStressed },
        },
        {
          path: Page.deviceTrait,
          loadComponent: () =>
            import('./quiz/questions/device-trait/device-trait.page.component').then(
              m => m.DeviceTraitPage,
            ),
          data: { page: Page.deviceTrait },
        },
        {
          path: Page.healthGoals,
          loadComponent: () =>
            import('./quiz/questions/health-goals/health-goals.page.component').then(
              m => m.HealthGoalsPage,
            ),
          data: { page: Page.healthGoals },
        },
        {
          path: Page.biggestStressor,
          loadComponent: () =>
            import('./quiz/questions/biggest-stressor/biggest-stressor.page.component').then(
              m => m.BiggestStressorPage,
            ),
          data: { page: Page.biggestStressor },
        },
        {
          path: Page.favoriteWayToUnwind,
          loadComponent: () =>
            import(
              './quiz/questions/favorite-way-to-unwind/favorite-way-to-unwind.page.component'
            ).then(m => m.FavoriteWayToUnwindPage),
          data: { page: Page.favoriteWayToUnwind },
        },
        {
          path: Page.sleepWearable,
          loadComponent: () =>
            import('./quiz/questions/sleep-wearable/sleep-wearable.page.component').then(
              m => m.SleepWearablePage,
            ),
        },
        {
          path: Page.whichWearable,
          loadComponent: () =>
            import('./quiz/questions/which-wearable/which-wearable.page.component').then(
              m => m.WhichWearablePage,
            ),
        },
        {
          path: Page.eligibleAppleWatch,
          loadComponent: () =>
            import(
              './quiz/questions/eligible-apple-watch/eligible-apple-watch.page.component'
            ).then(m => m.EligibleAppleWatchPage),
        },
        {
          path: Page.purchaseNewWatch,
          loadComponent: () =>
            import('./quiz/questions/purchase-new-watch/purchase-new-watch.page.component').then(
              m => m.PurchaseNewWatchPage,
            ),
        },
        {
          path: Page.wearYourWatchToBed,
          loadComponent: () =>
            import(
              './quiz/questions/wear-your-watch-to-bed/wear-your-watch-to-bed.page.component'
            ).then(m => m.WearYourWatchToBedPage),
        },
        {
          path: Page.fitResult,
          loadComponent: () =>
            import('./quiz/fit-result-info/fit-result-info.page.component').then(
              m => m.FitResultInfoPage,
            ),
        },
        {
          path: Page.deviceResult,
          loadComponent: () =>
            import('./quiz/device-result-info/device-result-info.page.component').then(
              m => m.DeviceResultInfoPage,
            ),
        },
        {
          path: Page.resultOverview,
          loadComponent: () =>
            import('./quiz/result-overview/result-overview.page.component').then(
              m => m.ResultOverviewPage,
            ),
          data: { page: Page.resultOverview },
        },
        ...ab312Routes,

        { path: '', redirectTo: Page.fertilityGoal, pathMatch: 'full' },
        // Will redirect to the last page in the quiz flow
        { path: Page.lastQuizPage, loadChildren: () => [] },
      ] satisfies Route[]
    ).map(route => ({
      ...route,
      data: { page: route.path },
    })),
}

const MAIN_ROUTES: Routes = (
  [
    {
      path: Page.oura,
      canActivate: [CanActivateOuraPage],
      pathMatch: 'full',
      children: [],
    },
    {
      path: Page.plans,
      canLoad: [BootstrapDone],
      loadChildren: () => import('./plans/plans.page.module').then(m => m.PlansPageModule),
    },
    {
      path: Page.goal,
      loadChildren: () => import('./goal/goal.page.module').then(m => m.GoalPageModule),
    },
    {
      path: Page.measuringDevice,
      loadChildren: () =>
        import('./measuring-device/measuring-device.page.module').then(
          m => m.MeasuringDevicePageModule,
        ),
    },
    {
      path: Page.coveredByInsurance,
      loadComponent: () =>
        import('../components/covered-by-insurance/covered-by-insurance.page.component').then(
          m => m.CoveredByInsurancePage,
        ),
      data: { page: Page.coveredByInsurance },
    },
    {
      path: Page.chooseProvider,
      loadComponent: () =>
        import('../components/choose-provider/choose-provider.page.component').then(
          m => m.ChooseProviderPage,
        ),
      data: { page: Page.chooseProvider },
    },
    {
      path: Page.providerInfo,
      loadComponent: () =>
        import('../components/provider-info/provider-info.page.component').then(
          m => m.ProviderInfoPage,
        ),
      data: { page: Page.providerInfo },
    },
    {
      path: Page.clarifyProvider,
      loadComponent: () =>
        import('../components/clarify-provider/clarify-provider.page.component').then(
          m => m.ClarifyProviderPage,
        ),
      data: { page: Page.clarifyProvider },
    },
    {
      path: Page.credentials,
      loadChildren: () =>
        import('./credentials/credentials.page.module').then(m => m.CredentialsPageModule),
    },
    {
      path: Page.address,
      loadComponent: () => import('./address/address.page.component').then(m => m.AddressPage),
    },
    {
      path: Page.payment,
      loadChildren: () => import('./payment/payment.page.module').then(m => m.PaymentPageModule),
    },
    {
      path: Page.orderSummary,
      loadChildren: () =>
        import('./order-summary/order-summary.page.module').then(m => m.OrderSummaryPageModule),
    },
  ] satisfies Route[]
).map(route => ({
  ...route,
  canActivate: [...(route.canActivate || []), getFlowGuard(route.path)],
}))

const MANAGE_ACCOUNT_ROUTES: Routes = [
  {
    path: ManageAccountPage.account,
    canLoad: [BootstrapDone],
    component: PageOutletComponent,
    loadChildren: () => [
      {
        path: '',
        loadChildren: () =>
          import('./manage-account/account/account.page.module').then(m => m.AccountPageModule),
      },
      {
        path: Page.login,
        loadChildren: () =>
          import('./manage-account/login/login.page.module').then(m => m.LoginPageModule),
      },
      {
        path: ManageAccountPage.billing,
        canLoad: [BootstrapDone],
        loadChildren: () => [
          {
            path: '',
            loadChildren: () =>
              import('./manage-account/billing/billing.page.module').then(m => m.BillingPageModule),
          },
          {
            path: ManageAccountPage.plans,
            canLoad: [BootstrapDone],
            canActivate: [CanActivateAccountPage],
            loadChildren: () =>
              import('./manage-account/plans/plans.module').then(m => m.PlansPageModule),
          },
          {
            path: Page.address,
            canLoad: [BootstrapDone],
            canActivate: [CanActivateAccountPage],
            loadComponent: () =>
              import('./manage-account/address/address.page').then(m => m.AddressPage),
          },
          {
            path: Page.payment,
            canLoad: [BootstrapDone],
            canActivate: [CanActivateAccountPaymentPage],
            loadComponent: () =>
              import('./manage-account/payment/payment.page.component').then(
                m => m.ManageAccountPaymentPage,
              ),
          },
          {
            path: ManageAccountPage.orderSummary,
            canLoad: [BootstrapDone],
            canActivate: [CanActivateAccountPage],
            loadComponent: () =>
              import('./manage-account/order-summary/order-summary.page.component').then(
                m => m.OrderSummaryPage,
              ),
          },
        ],
      },

      {
        path: ManageAccountPage.settings,
        loadChildren: () =>
          import('./manage-account/account-settings/account-settings.page.module').then(
            m => m.AccountSettingsPageModule,
          ),
      },
      {
        path: ManageAccountPage.profile,
        canLoad: [BootstrapDone],
        loadChildren: () =>
          import('./manage-account/profile/profile.page.module').then(m => m.ProfilePageModule),
      },
    ],
  },
]

const STATIC_ROUTES: Routes = [
  {
    path: ':lang',
    component: StaticMainPage,
    canActivate: [CanActivatePagesModule],
    providers: [
      {
        provide: PageFlowService,
        useClass: SignupFlowService,
      },
    ],
    children: [
      QUIZ_ROUTE,
      ...MANAGE_ACCOUNT_ROUTES,
      ...MAIN_ROUTES,
      ...CLEARBLUE_ROUTES,
      {
        path: Page.resetPassword,
        loadChildren: () =>
          import('./reset-password/reset-password.page.module').then(
            m => m.ResetPasswordPageModule,
          ),
      },
      {
        path: Page.code,
        loadChildren: () => import('./secret/secret.page.module').then(m => m.SecretPageModule),
      },
      {
        path: 'activate',
        canLoad: [BootstrapDone],
        loadChildren: () => [
          {
            path: ActivatePage.credentials,
            canActivate: [CanActivateActivateCredentials],
            loadComponent: () =>
              import('./activate/credentials/credentials.page.component').then(
                m => m.CredentialsPage,
              ),
          },
          {
            path: ActivatePage.orderSummary,
            canActivate: [CanActivateActivateOrderSummary],
            loadComponent: () =>
              import('./activate/order-summary/order-summary.page.component').then(
                m => m.OrderSummaryPage,
              ),
          },
        ],
      },
      { path: '', redirectTo: Page.measuringDevice, pathMatch: 'full' },
      { path: 'signup', redirectTo: Page.measuringDevice, pathMatch: 'full' },
      { path: 'signup', redirectTo: '' },
      { path: '**', component: Error404Page },
    ],
  },
  {
    path: '**',
    redirectTo: LANG_DEFAULT,
  },
]

@NgModule({
  providers: [],
  imports: [CommonModule, SharedModule, RouterModule.forChild(STATIC_ROUTES)],
  declarations: [Error404Page, StaticMainPage, PageOutletComponent],
})
export class PagesModule {}
