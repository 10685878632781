import { animate, style, transition, trigger, useAnimation } from '@angular/animations'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { ModalService } from '@src/app/core/services/modal.service'
import { fadeAnimation } from '@src/app/core/util/animations.util'
import { ModalControl, ModalInterface } from '../../shared/typings/interfaces/modals'
import { ModalDirective } from './modal.directive'

@Component({
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('fadeAnimation', [transition('* => *', [useAnimation(fadeAnimation)])]),
    trigger('showAnimation', [
      transition('void => slideUp', [
        style({
          top: '100%',
        }),
        animate(
          '0.5s ease-in-out',
          style({
            top: '20%',
          }),
        ),
      ]),
    ]),
  ],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  private changeDetectionRef = inject(ChangeDetectorRef)
  private modalService = inject(ModalService)
  viewContainerRef!: ViewContainerRef

  readonly fadeAnimation = {
    value: '',
    params: {
      time: '0.5s',
      from: 0,
      to: 1,
    },
  }

  readonly fadeAnimationBg = {
    value: '',
    params: {
      time: '0.5s',
      from: 0,
      to: 0.7,
    },
  }

  @Input()
  modal!: ModalInterface

  @ViewChild(ModalDirective, { static: false })
  modalHost!: ModalDirective

  ngAfterViewInit(): void {
    if (this.modal.component) this.loadComponent()
    if (this.modal.modalCentered) {
      const modalElement = document.querySelector('.modal')
      modalElement?.classList.add('modal___centered')
    }
    if (this.modal.lightPurpleBackground) {
      const modalElement = document.querySelector('.modal__background')
      modalElement?.classList.add('modal__background___light')
    }
    this.changeDetectionRef.detectChanges()
    document.body.classList.add('modal--open')
  }

  loadComponent(): void {
    if (!this.modal.component) throw new Error('modal.component not defined')

    this.viewContainerRef = this.modalHost.viewContainerRef
    this.viewContainerRef.clear()

    const componentRef = this.viewContainerRef.createComponent(this.modal.component)
    componentRef.instance.api = this.modal.api
  }

  clear(control?: ModalControl): void {
    this.modalService.remove()
    control?.action?.()
  }

  ngOnDestroy(): void {
    document.body.classList.remove('modal--open')
  }
}
