import { inject, Injectable } from '@angular/core'
import { _isNotEmpty } from '@naturalcycles/js-lib'
import { LANG, LANG_DEFAULT, normalizeLanguage, toTranslatedLanguage } from '@naturalcycles/shared'
import { TranslateService } from '@ngx-translate/core'
import { SignalStore } from '@src/app/core/store/signalStore'
import { filter, firstValueFrom } from 'rxjs'
import { SeoService } from './seo.service'

@Injectable({ providedIn: 'root' })
export class LangService {
  private store = inject(SignalStore)
  private translateService = inject(TranslateService)
  private seoService = inject(SeoService)

  private getLang(): LANG {
    // remove leading slash
    const path = location.pathname.replace(/^\/+/, '')
    return toTranslatedLanguage(normalizeLanguage(path))
  }

  async init(): Promise<void> {
    this.translateService.setDefaultLang(LANG_DEFAULT)
    await this.setLang(this.getLang())
  }

  async setLang(lang: LANG): Promise<void> {
    console.log('Set lang: ' + lang)
    await firstValueFrom(this.translateService.use(lang).pipe(filter(_isNotEmpty)))
    this.seoService.setHtmlLang(lang)
    this.store.$lang.set(lang)
    this.store.$langLoaded.set(true)
  }

  async reloadLang(): Promise<void> {
    const lang = this.store.$lang()
    await firstValueFrom(this.translateService.reloadLang(lang).pipe(filter(_isNotEmpty)))
  }
}
