import { _objectKeys, StringMap } from '@naturalcycles/js-lib'
import { BucketEditType, getBucketEditsOfType } from '@naturalcycles/shared'
import { getState } from '../store/signalStore'

/**
 * Applies relevant bucket edits from all experiments to a translation map.
 */
export function applyBucketEdits(translations: StringMap, lang: string): StringMap {
  const {
    experiment: { assignments },
  } = getState()
  const textBucketEdits = _objectKeys(assignments).flatMap(experiment => {
    const assignment = assignments[experiment]
    if (!assignment) {
      return []
    }
    return [
      ...getBucketEditsOfType(assignment, BucketEditType.TranslationKey),
      ...getBucketEditsOfType(assignment, BucketEditType.Text),
    ]
  })

  for (const bucketEdit of textBucketEdits) {
    if (bucketEdit.type === BucketEditType.TranslationKey) {
      const replacement = translations[bucketEdit.replacementKey]
      if (replacement) {
        translations[bucketEdit.id] = replacement
      }
    } else {
      if (lang === bucketEdit.lang) {
        translations[bucketEdit.id] = bucketEdit.replacementText
      }
    }
  }
  return translations
}
