import { inject, Injectable } from '@angular/core'
import { getSignalStore } from '@src/app/core/store/signalStore'
import { CartService } from '../core/services/cart.service'
import { RouteGuard } from '../core/services/route-guard.service'
import { Page } from '../shared/typings/enum/pages'

@Injectable({ providedIn: 'root' })
export class CanActivateActivateCredentials extends RouteGuard {
  private cartService = inject(CartService)

  canActivate(): boolean {
    const cart = getSignalStore().$cart()
    const hasActivationCodeApplied = this.cartService.hasActivationCodeApplied(cart)

    if (!hasActivationCodeApplied) {
      void this.navService.goToPage([Page.code])
    }
    return true
  }
}
