@import '_var';
@import '_typography';

.button__nc {
  @include button-s;
  font-family: 'OpenSauceOne';
  padding: 16px;
  font-weight: 500;
  border-radius: 25px;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: block;
  &:active,
  :focus,
  :hover {
    outline: none;
  }

  &.fullwidth {
    width: 100%;
  }
  &.outline {
    background-color: transparent;
  }
  &.transparent {
    background-color: transparent;
    border-color: transparent;
  }
  &.link {
    text-decoration: underline;
  }
  &.loading {
    background-image: url('#{$imgPath}/spinner.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    min-height: 40px;
    color: transparent;
  }
}

.button__nc:disabled {
  opacity: 0.5;
}

.button__purple {
  @extend.button__nc;
  &.outline {
    color: $brandPurple;
  }
  box-sizing: border-box;
  background-color: $brandPurple;
  color: $white;
  border: 1px solid $brandPurple;
}

.button__pink {
  @extend.button__nc;
  color: $supportPurple;
  background-color: $brandSalmon;
  border: 1px solid $brandSalmon;
}

.button__blue {
  @extend.button__nc;
  color: $digitalBlue;
  background-color: $digitalBlue;
  border: 1px solid $digitalBlue;
}

.button__white {
  @extend.button__nc;
  background-color: $white;
  color: $brandPurple;
  border: 1px solid $brandPurple;

  &:hover,
  &:focus {
    border-color: $brandSalmon;
  }
}

.button__lightGrey {
  @extend.button__nc;
  background-color: $brandGrey;
  color: $brandPurple;
  border: 1px solid $brandPurple;
}

.button__vanillaIce {
  @extend.button__nc;
  background-color: $neutral300;
  color: $brandPurple;
}

.button__purpleBorder {
  background-color: transparent;
  border: 1px solid $brandPurple;
}

.button__vanillaIce:disabled,
.button__purpleBorder:disabled {
  background-color: $grey200;
  color: $white;
  border: none;
}
