import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  effect,
  inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { SignalStore } from '@src/app/core/store/signalStore'
import { ModalInterface } from '../../shared/typings/interfaces/modals'
import { ModalComponent } from './modal.component'
import { ModalDirective } from './modal.directive'

@Component({
  selector: 'app-modal-container',
  template: ` <ng-template modalHost /> `,
})
export class ModalContainerComponent {
  private store = inject(SignalStore)
  private componentFactoryResolver = inject(ComponentFactoryResolver)
  private changeDetectionRef = inject(ChangeDetectorRef)

  viewContainerRef!: ViewContainerRef
  @ViewChild(ModalDirective, { static: false })
  modalHost!: ModalDirective // todo

  constructor() {
    effect(() => {
      const modal = this.store.$modal()

      if (modal) {
        this.loadComponent(modal)
      } else {
        this.clear()
      }
    })
  }

  private loadComponent(modal: ModalInterface): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent)

    this.viewContainerRef = this.modalHost.viewContainerRef

    this.viewContainerRef.clear()

    const componentRef = this.viewContainerRef.createComponent(componentFactory)

    componentRef.instance.modal = modal

    this.changeDetectionRef.detectChanges()
  }

  private clear(): void {
    this.viewContainerRef?.clear()
  }
}
