import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { toTranslatedLanguageOrUndefined } from '@naturalcycles/shared'

@Injectable({ providedIn: 'root' })
export class CanActivatePagesModule {
  canActivate(route: ActivatedRouteSnapshot): boolean {
    return !!toTranslatedLanguageOrUndefined(route.params['lang'])
  }
}
