<app-navbar />

<router-outlet />

<app-spinner-component />

<app-modal-container />

@if (!store.$bootstrapComplete()) {
  <app-loading />
}
