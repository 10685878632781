import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router'
import { Experiment, RegistrationFlow, WebSignupQueryParam } from '@naturalcycles/shared'
import { ExperimentService } from '@src/app/core/services/experiment.service'

@Injectable({ providedIn: 'root' })
export class CanActivateAb244Module {
  private experimentService = inject(ExperimentService)

  canActivate: CanActivateFn = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
    /*
     * Entry points for Oura flow
     * - /en-US/secret?code=oura10&ignoreConfirmation=true -> /oura -> /measuring-device?flow=1&measuringDevice=2
     * - /oura -> /measuring-device?flow=1&measuringDevice=2
     * - /measuring-device?flow=1&measuringDevice=2
     * - provide 'oura10' on secret page -> /oura -> /measuring-device?flow=1&measuringDevice=2
     *
     * All entry points lead to /measuring-device?flow=1&measuringDevice=2,
     * that's why we're firing the impression here
     *
     */
    const urlSearchParams = route.queryParams
    const flowParam = urlSearchParams[WebSignupQueryParam.flow]
    const isOuraFlow = flowParam === RegistrationFlow.OURA.toString()
    if (isOuraFlow) {
      await this.experimentService.logImpression(Experiment.SIGNUP_QUIZ_IN_OURA_FLOW)
    }

    return true
  }
}
