import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, TranslateModule],
  exports: [],
  providers: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }

  // singleton services go on the forRoot method, non singleton should be in the providers array on @NgModule
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    }
  }
}
