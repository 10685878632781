import { Routes } from '@angular/router'
import { Page } from '@src/app/shared/typings/enum/pages'

export const ab264Routes: Routes = [
  {
    path: Page.resultOverview,
    loadComponent: () =>
      import('./pages/result-overview/result-overview.page.component').then(
        m => m.ResultOverviewPage,
      ),
    data: { page: Page.resultOverview },
  },
  {
    path: Page.supportFertilityGoals,
    loadComponent: () =>
      import('./pages/support-fertility-goals/support-fertility-goals.page.component').then(
        m => m.SupportFertilityGoalsPage,
      ),
    data: { page: Page.supportFertilityGoals },
  },
  {
    path: Page.gainConfidence,
    loadComponent: () =>
      import('./pages/gain-confidence/gain-confidence.page.component').then(
        m => m.GainConfidencePage,
      ),
    data: { page: Page.gainConfidence },
  },
  {
    path: Page.recommendDevice,
    loadComponent: () =>
      import('./pages/recommended-device/recommended-device.page.component').then(
        m => m.RecommendedDevicePage,
      ),
    data: { page: Page.recommendDevice },
  },
]
