import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CartComponentModule } from '@src/app/components/cart/cart.component.module'
import { ConfirmCartComponent } from '@src/app/components/modals/confirm-discount/confirm-discount.component'
import { SharedModule } from '@src/app/shared/shared.module'

@NgModule({
  declarations: [ConfirmCartComponent],
  imports: [CommonModule, SharedModule, CartComponentModule],
  exports: [ConfirmCartComponent],
})
export class ConfirmDiscountComponentModule {}
