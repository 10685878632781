import { animate, style, transition, trigger, useAnimation } from '@angular/animations'
import { Component, computed, effect, inject, ViewContainerRef } from '@angular/core'
import { ModalService } from '@src/app/core/services/modal.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { fadeAnimation } from '@src/app/core/util/animations.util'
import { ModalControl } from '@src/app/shared/typings/interfaces/modals'
@Component({
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  selector: 'app-modal',
  animations: [
    trigger('fadeAnimation', [transition('void => *', [useAnimation(fadeAnimation)])]),
    trigger('showAnimation', [
      transition('void => slideUp', [
        style({
          top: '100%',
        }),
        animate(
          '0.5s ease-in-out',
          style({
            top: '20%',
          }),
        ),
      ]),
    ]),
  ],
  standalone: false,
})
export class ModalComponent {
  private modalService = inject(ModalService)
  private store = inject(SignalStore)
  viewContainerRef!: ViewContainerRef

  readonly fadeAnimation = {
    value: '',
    params: {
      time: '0.5s',
      from: 0,
      to: 1,
    },
  }

  readonly fadeAnimationBg = {
    value: '',
    params: {
      time: '0.5s',
      from: 0,
      to: 0.7,
    },
  }

  protected $modals = computed(() => this.store.$modals())

  constructor() {
    effect(() => document.body.classList.toggle('modal--open', !!this.$modals().length))
  }

  clear(controls?: ModalControl[]): void {
    this.modalService.remove()
    controls?.forEach(control => control?.action?.())
  }
}
