import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { Experiment, WebSignupQueryParam } from '@naturalcycles/shared'
import { SignalStore } from '@src/app/core/store/signalStore'
import { Page, ParentPage } from '@src/app/shared/typings/enum/pages'

// Short lived hack to avoid having to change the redirect url for apple auth
// in the backend for this experiment. Cross-repo forced experiments do not work
// so this is a quick workaround for easy testing.
export const ab282AppleAuthGuard: CanActivateFn = (_route, state) => {
  const store = inject(SignalStore)
  const router = inject(Router)
  const appleAuthParam = state.root.queryParams[WebSignupQueryParam.appleAuth]
  if (
    appleAuthParam &&
    store.$experiment().assignments[Experiment.LEAD_CAPTURE]?.bucket === 'test'
  ) {
    const lang = store.$lang()
    return router.createUrlTree([lang, ParentPage.quiz, Page.fitResult], {
      queryParams: { [WebSignupQueryParam.appleAuth]: appleAuthParam },
    })
  }
  return true
}
