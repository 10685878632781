import { inject, Injectable } from '@angular/core'
import { _isNotEmptyObject, _Memo } from '@naturalcycles/js-lib'
import { WebsignupInitQueryParams } from '@naturalcycles/shared'
import { MixpanelEvent } from '@src/app/shared/typings/analytics'
import { MixpanelService } from './analytics/mixpanel.service'
import { api } from './api.service'
import { cookieService } from './cookie.service'

@Injectable({ providedIn: 'root' })
export class AppService {
  private readonly mixpanelService = inject(MixpanelService)
  @_Memo()
  async webSignupInit(params: WebsignupInitQueryParams): Promise<void> {
    this.mixpanelService.trackEvent(MixpanelEvent.WebSignupInit, {
      utms: params,
    })

    if (_isNotEmptyObject(params)) {
      cookieService.setCookie('utms', JSON.stringify(params), 30)
    } else {
      cookieService.deleteCookie('utms')
    }

    await api.get('app/webSignupInit', {
      searchParams: params,
    })
  }
}
